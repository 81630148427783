import {
  analytics,
  getEventTrackEnabled,
  JITSU_LOG_TAG,
  getKioskSettings,
  getFormattedItem,
  getOrderTime,
  formatMenuProductToAnalyticsProduct,
  formatProductsCart,
  formatProduct,
} from '../utilities';

const getBaseURLWithHome = () => {
  return window.location.origin ? `${window.location.origin}/home` : 'https://localhost:4000/home';
};

const trackEvent = (eventName, eventProperties) => {
  if (!getEventTrackEnabled()) return;
  try {
    analytics.track(eventName, eventProperties);
  } catch (error) {
    logger.error(`${JITSU_LOG_TAG} - ${eventName} error: ${error.message}`);
  }
};

const setDineMode = async (dine_mode, settings) => {
  try {
    logger.info(`${JITSU_LOG_TAG} - Sending Dine Mode Selected event`);

    if (process.env.POI_APP_IS_NEW_GA4_ENABLED) {
      const kioskSettings = getKioskSettings(settings);

      trackEvent('Dine Mode Selected', {
        ...kioskSettings,
        disposition: dine_mode,
      });
    } else {
      trackEvent('Dine Mode Selected', {
        dine_mode,
        context: {
          page: {
            path: '/home',
            url: getBaseURLWithHome(),
          },
        },
      });
    }
  } catch (error) {
    logger.error(`${JITSU_LOG_TAG} - Error Dine Mode Selected event: ${error.message}`);
  }
};

const setPage = async page_info => {
  trackEvent('page', { page_info });
};

const setProductRemoved = async (
  product,
  quantity,
  settings,
  orderTotal,
  couponNumber,
  addedUpsells,
) => {
  try {
    logger.info(`${JITSU_LOG_TAG} - Sending Product Removed event`);
    const { kioskId, currency, storeName } = getKioskSettings(settings);
    const mainProduct = addedUpsells?.find(upsell => upsell.productId === product.productId);

    const formattedItem = getFormattedItem({
      product,
      currency,
      quantity,
      couponNumber,
      itemCategory: product.parentCategory,
      main: mainProduct?.main,
    });

    trackEvent('Product Removed', {
      storeName,
      kioskID: kioskId,
      ecommerce: {
        value: orderTotal,
        currency,
        items: [{ ...formattedItem }],
      },
    });
  } catch (error) {
    logger.error(`${JITSU_LOG_TAG} - Error Product Removed event: ${error.message}`);
  }
};

const setProductAddedToCart = async (product, settings, couponNumber = '', itemCategory = '') => {
  try {
    logger.info(`${JITSU_LOG_TAG} - Sending Product Added event`);
    if (product?.type === 'grouped') return;

    if (process.env.POI_APP_IS_NEW_GA4_ENABLED) {
      const { currency, kioskId, storeName } = getKioskSettings(settings);
      const formattedProduct = getFormattedItem({
        product,
        currency,
        couponNumber,
        itemCategory,
        quantity: product?.count?.current || 1,
      });

      trackEvent('Product Added', {
        storeName,
        kioskID: kioskId,
        ecommerce: {
          currency,
          value: product?.price?.current,
          coupon: couponNumber,
          items: [{ ...formattedProduct }],
        },
      });
    } else {
      if (product?.type === 'grouped') return;
      const formattedProduct = formatProduct(product);

      trackEvent('Product Added', {
        ...formattedProduct,
        quantity: product?.count?.current,
      });
    }
  } catch (error) {
    logger.error(`${JITSU_LOG_TAG} - Error Product Added event: ${error.message}`);
  }
};

const setUpsellAddedToCart = (product, settings, couponNumber, main) => {
  try {
    logger.info(`${JITSU_LOG_TAG} - Sending Upsell Added event`);

    if (process.env.POI_APP_IS_NEW_GA4_ENABLED) {
      const { currency, kioskId, storeName } = getKioskSettings(settings);
      const formattedProduct = getFormattedItem({ product, currency, couponNumber, main });

      trackEvent('Upsell Added', {
        storeName,
        kioskID: kioskId,
        ecommerce: {
          currency,
          value: product.price,
          coupon: couponNumber,
          items: [{ ...formattedProduct }],
        },
      });
    } else {
      const formattedProduct = formatProduct(product, main);

      trackEvent('Upsell Added', {
        ...formattedProduct,
        quantity: 1,
      });
    }
  } catch (error) {
    logger.error(`${JITSU_LOG_TAG} - Error adding upsell to cart error: ${error.message}`);
  }
};

const setOrderViewed = async (items, settings, total, couponNumber) => {
  try {
    logger.info(`${JITSU_LOG_TAG} - Sending Order Viewed event`);
    const kioskSettings = getKioskSettings(settings);
    const formattedItems = items?.map((item, idx) =>
      getFormattedItem({
        product: item,
        currency: kioskSettings.currency,
        quantity: item.quantity,
        couponNumber,
        idx,
        itemCategory: item.parentCategory,
      }),
    );

    trackEvent('Order Viewed', {
      ...kioskSettings,
      ecommerce: {
        currency: kioskSettings?.currency,
        value: total,
        item_list_name: '',
        item_list_id: '',
        items: [...formattedItems],
      },
    });
  } catch (error) {
    logger.error(`${JITSU_LOG_TAG} - Error Order Viewed event: ${error.message}`);
  }
};

const setOrderUpdated = async () => {
  trackEvent('Order Updated');
};

const setOrderCompleted = async () => {
  trackEvent('Order Completed');
};

const setPaymentStarted = async paymentType => {
  trackEvent('Payment Started', { paymentType });
};

const setUpsellDismissed = async products => {
  trackEvent('Upsell Dismissed', { products });
};

const setUpsizeItemSelected = (product, settings) => {
  try {
    logger.info(`${JITSU_LOG_TAG} - Sending Upsize Selected event`);
    const kioskSettings = getKioskSettings(settings);
    const { name, parentCategory } = product;

    trackEvent('Upsize Selected', {
      ...kioskSettings,
      upsizeProduct: name?.text || name,
      size: 'large',
      upsizeSelectedFrom: parentCategory,
    });
  } catch (error) {
    logger.error(`${JITSU_LOG_TAG} - Error Upsize selected event: ${error.message}`);
  }
};

const setPaymentCompleted = async (order, cart, couponNumber, addedUpsells) => {
  try {
    logger.info(`${JITSU_LOG_TAG} - Sending Payment Completed event`);

    if (process.env.POI_APP_IS_NEW_GA4_ENABLED) {
      const paymentType = order?.paymentType || order.payment?.paymentType;

      const {
        orderNumber,
        transactionId,
        settings,
        session,
        financialSummary,
        cart: { mode: orderMode } = {},
      } = order;
      const { timestamp } = session;
      const { kiosk } = settings;
      const { serial, site: { name: storeName } = {}, currency } = kiosk;
      const { taxes, total: totalBill } = financialSummary;

      const { orderTime, orderDay } = getOrderTime(timestamp);

      const cartItems = cart?.map((item, idx) => {
        const mainProduct = addedUpsells?.find(upsell => upsell.productId === item.id);
        return getFormattedItem({
          product: item,
          currency,
          quantity: item.count?.current,
          couponNumber,
          idx,
          itemCategory: item.parentCategory,
          main: mainProduct?.main,
        });
      });

      trackEvent('Payment Completed', {
        storeName,
        kioskID: serial,
        payment_type: paymentType,
        orderTime,
        disposition: orderMode,
        purchase_day: orderDay,
        ecommerce: {
          transaction_id: transactionId || orderNumber,
          tax: taxes,
          shipping: 0,
          coupon: couponNumber,
          value: totalBill || null,
          currency,
          items: [...cartItems],
        },
      });
    } else {
      const products = formatProductsCart(cart, addedUpsells);
      const items = cart.length;
      const total = order.cart?.total || null;
      const paymentType = order?.paymentType || order.payment?.paymentType;
      const cardType = order.payment?.cardType;
      const { orderNumber, transactionId } = order;

      trackEvent('Payment Completed', {
        items,
        products,
        revenue: total,
        paymentType,
        cardType,
        orderNumber,
        transactionId,
      });
    }
  } catch (error) {
    logger.error(`${JITSU_LOG_TAG} - Error Payment Completed event: ${error.message}`);
  }
};

const setProductViewed = async (id, product, currency, settings, couponNumber, itemCategory) => {
  try {
    logger.info(`${JITSU_LOG_TAG} - Sending Product Viewed event`);

    if (process.env.POI_APP_IS_NEW_GA4_ENABLED) {
      const kioskSettings = getKioskSettings(settings);

      const formattedProduct = getFormattedItem({
        product,
        currency,
        quantity: 1,
        couponNumber,
        itemCategory,
      });

      trackEvent('Product Viewed', {
        ...kioskSettings,
        value: product.price,
        ecommerce: {
          items: [
            {
              ...formattedProduct,
              item_id: id,
              item_name: product?.name
                ? product?.name?.text
                : product?.grouped
                ? product?.grouped
                    .map(item => `${item?.name?.text}  | `)
                    .join(' ')
                    .slice(0, -3)
                : product?.options
                ? product?.options
                    .map(item => `${item?.name?.text}  | `)
                    .join(' ')
                    .slice(0, -3)
                : '',
            },
          ],
        },
      });
    } else {
      trackEvent('Product Viewed', {
        product_id: id,
        product_name: product?.name
          ? product?.name?.text
          : product?.grouped
          ? product?.grouped
              .map(item => `${item?.name?.text}  | `)
              .join(' ')
              .slice(0, -3)
          : product?.options
          ? product?.options
              .map(item => `${item?.name?.text}  | `)
              .join(' ')
              .slice(0, -3)
          : '',
        product_type: product?.type || product?.dataTag?.split('-').shift() || '',
        price: product?.price,
        currency,
      });
    }
  } catch (error) {
    logger.error(`${JITSU_LOG_TAG} - Error Product Viewed event: ${error.message}`);
  }
};

const setLoyaltyLoginSuccessTrack = async () => {
  trackEvent('Loyalty login success', {});
};

const setLoyaltyLogoutSuccessTrack = async () => {
  trackEvent('Loyalty logout success', {});
};

const setLoyaltyLoginFailTrack = async () => {
  trackEvent('Loyalty login fail', {});
};

const setLoyaltyClaimTrack = async data => {
  trackEvent('Loyalty claim', data);
};

const setLoyaltyGuestClaimTrack = async data => {
  trackEvent('Loyalty guest claim', data);
};

const setBeginCheckoutTrack = async (
  items,
  settings,
  cartTotal,
  couponNumber,
  orderMode,
  upsellDependency,
) => {
  try {
    logger.info(`${JITSU_LOG_TAG} - Sending Begin Checkout event`);
    const kioskSettings = getKioskSettings(settings);

    const formattedItems = items?.map((item, idx) => {
      const mainProduct = upsellDependency?.find(upsell => upsell.productId === item.id);
      return getFormattedItem({
        product: item?.product,
        currency: kioskSettings.currency,
        quantity: item.quantity,
        couponNumber,
        idx,
        itemCategory: item.parentCategory,
        main: mainProduct?.main,
      });
    });

    trackEvent('Begin Checkout', {
      ...kioskSettings,
      disposition: orderMode,
      ecommerce: {
        coupon: couponNumber,
        value: cartTotal,
        currency: kioskSettings.currency,
        items: [...(formattedItems || [])],
      },
    });
  } catch (error) {
    logger.error(
      `${JITSU_LOG_TAG} - Error while sending Begin checkout event error: ${error.message}`,
    );
  }
};

const setViewItemListTrack = async ({ deviceSettings, products, overrides }) => {
  try {
    logger.info(`${JITSU_LOG_TAG} - Sending View Item List event`);

    const formattedDeviceSettings = getKioskSettings(deviceSettings);
    const { item_list_name = '', item_id = '' } = overrides ?? {};

    const items = products?.map((menuProduct, index) => {
      const menuProductOverrides = {
        item_list_name,
        item_list_id: item_id,
        index: index + 1,
      };
      return formatMenuProductToAnalyticsProduct({ menuProduct, overrides: menuProductOverrides });
    });

    const eventData = {
      ...formattedDeviceSettings,
      ecommerce: {
        item_list_name,
        item_id,
        items,
      },
    };

    trackEvent('View Item List', eventData);
  } catch (error) {
    logger.error(
      `${JITSU_LOG_TAG} - Error while sending View Item List event error: ${error.message}`,
    );
  }
};

const setCartItemEdit = async settings => {
  try {
    logger.info(`${JITSU_LOG_TAG} - Sending Cart item edit event`);
    const kioskSettings = getKioskSettings(settings);

    trackEvent('Cart Item Edit', { ...kioskSettings });
  } catch (error) {
    logger.error(
      `${JITSU_LOG_TAG} - Error while sending Cart item edit event error: ${error.message}`,
    );
  }
};

const setItemSelectTrack = async (product, cartItems, settings, couponNumber, itemCategory) => {
  try {
    logger.info(`${JITSU_LOG_TAG} - Sending Select Item event`);
    const kioskSettings = getKioskSettings(settings);

    const productDetails = {
      quantity: 1,
      product,
      parentCategory: itemCategory,
    };
    let formattedItems = [productDetails, ...cartItems];

    formattedItems = formattedItems?.map((item, idx) =>
      getFormattedItem({
        product: item.product,
        currency: kioskSettings.currency,
        quantity: item.quantity,
        couponNumber,
        idx,
        itemCategory: product.parentCategory,
      }),
    );

    trackEvent('Select Item', {
      ...kioskSettings,
      ecommerce: {
        item_list_name: '', // TODO
        item_list_id: '', // TODO
        items: formattedItems,
      },
    });
  } catch (error) {
    logger.error(
      `${JITSU_LOG_TAG} - Error while sending Item select event error: ${error.message}`,
    );
  }
};

const setEmbeddedErrorTrack = async (settings, errorMessage, pageTitle) => {
  try {
    logger.info(`${JITSU_LOG_TAG} - Sending Show Embedded Error event`);
    const kioskSettings = getKioskSettings(settings);

    trackEvent('Show Embedded Error', {
      ...kioskSettings,
      popupOrPageTitle: pageTitle,
      errorMessage,
    });
  } catch (error) {
    logger.error(
      `${JITSU_LOG_TAG} - Error while Show embedded error event error: ${error.message}`,
    );
  }
};

export const useSetTrack = () => {
  return {
    setDineMode,
    setProductAddedToCart,
    setProductRemoved,
    setUpsellAddedToCart,
    setOrderUpdated,
    setOrderCompleted,
    setPaymentStarted,
    setOrderViewed,
    setPaymentCompleted,
    setProductViewed,
    setUpsellDismissed,
    setPage,
    setBeginCheckoutTrack,
    setViewItemListTrack,
  };
};

export {
  setDineMode,
  setProductAddedToCart,
  setProductRemoved,
  setUpsellAddedToCart,
  setOrderUpdated,
  setOrderCompleted,
  setPaymentStarted,
  setOrderViewed,
  setPaymentCompleted,
  setProductViewed,
  setUpsellDismissed,
  setPage,
  setLoyaltyLoginSuccessTrack,
  setLoyaltyLogoutSuccessTrack,
  setLoyaltyLoginFailTrack,
  setLoyaltyClaimTrack,
  setLoyaltyGuestClaimTrack,
  setBeginCheckoutTrack,
  setUpsizeItemSelected,
  setViewItemListTrack,
  setCartItemEdit,
  setItemSelectTrack,
  setEmbeddedErrorTrack,
};
